<!-- =========================================================================================
    File Name: Calendar.vue
    Description: Calendar App
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div id="accessDashboard">
    <feather-icon class="sm:inline-flex xl:hidden cursor-pointer" icon="MenuIcon" style="height: 20px; margin-right: 10px;" @click.stop="showSidebar"></feather-icon>
    <vs-table ref="table" pagination search :max-items="renderMethod.itemsPerPage" :data="items">
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

        <div style="margin-right: 15px; margin-bottom: 10px">
          선택시점 이전 6시간 표시: <date-picker v-model="date" type="datetime" :clearable="false"/>
        </div>

        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mr-4 mb-4">
          <div class="p-4 border border-solid border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{ ((currentx - 1) * renderMethod.itemsPerPage) + 1 }} - {{ numOfItems >= renderMethod.itemsPerPage ? (currentx * renderMethod.itemsPerPage) : numOfItems }} of {{ numOfItems }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>
            <vs-dropdown-item @click="changeItemPerPage(4)">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="changeItemPerPage(10)">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="changeItemPerPage(15)">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="changeItemPerPage(30)">
              <span>30</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="changeItemPerPage(50)">
              <span>50</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="changeItemPerPage(100)">
              <span>100</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="changeItemPerPage(1000)">
              <span>1000</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="time">요청시간</vs-th>
        <vs-th sort-key="id">기자번호</vs-th>
        <vs-th sort-key="name">이름</vs-th>
        <vs-th sort-key="ip">접속IP</vs-th>
        <vs-th sort-key="url">요청주소</vs-th>
        <vs-th sort-key="data">요청데이터</vs-th>
      </template>
      <template slot-scope="{data}">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td><div>{{ moment(tr.time).format('YYYY-MM-DD HH:mm:ss') }}</div></vs-td>
            <vs-td><div>{{ tr.id }}</div></vs-td>
            <vs-td><div>{{ tr.name }}</div></vs-td>
            <vs-td><div>{{ tr.ip }}</div></vs-td>
            <vs-td><div>{{ tr.url }}</div></vs-td>
            <vs-td><div>{{ JSON.stringify(tr.data).substring(0, 20) }}</div></vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<style lang="scss">
#accessDashboard{
}
</style>


<script>
import moment from 'moment';
import { setTimeout } from 'timers';

import DatePicker from 'vue2-datepicker';

export default {
  data() {
    return {
      items: [],
      date: new Date(),
      currentx: 1,
      numOfItems: 0,
      numOfPages: 0,
      renderMethod: {
        itemsPerPage: 30,
      },
      writerOption: [],
    }
  },
  components: {
      // 'full-calendar': require('vue-fullcalendar'),
      // FullCalendar,
    DatePicker,
  },
  beforeRouteLeave (to, from, next) {
      // this.activePromptAddEvent = false;
      // this.activePromptEditEvent = false;
      setTimeout(() => { next(); }, 100);
  },
  computed: {
    // departmentOption(){
    //   return this.$store.state.departmentOption
    // },
    // levelOption(){
    //   return this.$store.state.levelOption
    // },
  },
  methods: {
    moment(data){
      return moment(data);
    },
    httpErrorFunction(error){
      let errorText = error.toString();
      if(errorText.includes('401')){
        alert('로그인 해주세요!')
        this.$router.push({path: '/pages/login?to='+ this.$router.history.current.path, query: this.$router.history.current.query})
      }
      this.$vs.notify({
        title:'Error',
        text: error,
        color:'danger',
        iconPack: 'feather',
        icon:'icon-alert-circle'})
      this.$vs.loading.close(); 
    },
    showSidebar() {
        this.$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', true);
    },
    async created(){
      this.$vs.loading({
        scale: 1.5
      });
      let start = moment(this.date).add(-30, 'minute');
      let end = moment(this.date);
      let fulldata = []
      for(let i = 0; i < 12; i++){
        try {
          let response = await this.$http.post('/api/getLogs', {start, end});
          fulldata = fulldata.concat(response.data);
          // console.log(response);
        } catch (error) {
          this.httpErrorFunction(error);
        }
        start = start.add(-1, 'h');
        end = end.add(-1, 'h');
      }
      console.log(fulldata);
      for(const data of fulldata){
        let writer = this.writerOption.find(e => e.idx == data.id);
        data.name = writer ? writer.name : '';
        if(data.url == '/api/getNewsList'){
          data.url = '뉴스리스트'
        }else if(data.url == '/api/getSingleNews'){
          data.url = '기사보기'
        }else if(data.url == '/api/getSingleNews/detail'){
          data.url = '기사상세'
        }else if(data.url == '/api/newsPlace/get'){
          data.url = '뉴스배치 보기'
        }else if(data.url == '/api/newsPlace/history'){
          data.url = '뉴스배치 기록'
        }else if(data.url == '/api/getNewsList/placeMain'){
          data.url = '뉴스배치 메인기사리스트'
        }else if(data.url == '/api/getNewsList/place'){
          data.url = '뉴스배치 기사리스트'
        }else if(data.url == '/api/newsPlace/preview'){
          data.url = '뉴스배치 미리보기'
        }else if(data.url == '/api/newsPlace/set'){
          data.url = '뉴스배치 저장'
        }else if(data.url == '/api/sendNewsflash'){
          data.url = '네이버 속보 보내기'
        }else if(data.url == '/api/writeArticles'){
          data.url = '기사저장'
        }else if(data.url == '/api/writeArticles/lock'){
          data.url = '기사작성 잠금'
        }else if(data.url == '/api/writeArticles/preview'){
          data.url = '기사작성 미리보기'
        }else if(data.url == '/api/getNewsList/photo'){
          data.url = '포토리스트'
        }else if(data.url == '/api/photo/setPhoto'){
          data.url = '포토저장'
        }else if(data.url == '/api/photo/editPhoto'){
          data.url = '포토수정'
        }else if(data.url == '/api/board/getList'){
          data.url = '게시판리스트'
        }else if(data.url == '/api/board/getPost'){
          data.url = '게시판 상세보기'
        }else if(data.url == '/api/board/getTimeOffListYear'){
          data.url = '직원휴가현황'
        }else if(data.url == '/api/board/getUserTimeOff'){
          data.url = '휴가신청 보기 / 휴가통계'
        }else if(data.url == '/api/board/getHolydayList'){
          data.url = '휴일리스트'
        }else if(data.url == '/api/board/getTimeOffList'){
          data.url = '휴가신청 리스트'
        }else if(data.url == '/api/board/setTimeOff'){
          data.url = '휴가신청 작성'
        }else if(data.url == '/api/board/editTimeOff'){
          data.url = '휴가신청 수정'
        }else if(data.url == '/api/board/deleteTimeOff'){
          data.url = '휴가신청 삭제'
        }else if(data.url == '/api/statistics/desc'){
          data.url = '통계 조회수 순'
        }else if(data.url == '/api/statistics/days'){
          data.url = '통계 날짜 별'
        }else if(data.url == '/api/statistics/eachReporter'){
          data.url = '통계 작성자 별'
        }else if(data.url == '/api/updateHuboNews'){
          data.url = '후보기사 변경'
        }else if(data.url == '/api/board/getOverTimeList'){
          data.url = '야근일지 리스트'
        }else if(data.url == '/api/board/setOverTime'){
          data.url = '야근일지 작성'
        }else if(data.url == '/api/board/editOverTime'){
          data.url = '야근일지 수정'
        }else if(data.url == '/api/board/deleteOverTime'){
          data.url = '야근일지 삭제'
        }else if(data.url == '/api/manageUsers/reporterlist'){
          data.url = '기자관리 리스트'
        }else if(data.url == '/api/manageUsers/getReporter'){
          data.url = '기자관리 기자 상세'
        }else if(data.url == '/api/manageUsers/createReporter'){
          data.url = '기자관리 생성'
        }else if(data.url == '/api/manageUsers/editReporter'){
          data.url = '기자관리 수정'
        }else if(data.url == '/api/getVarList/writer'){
          data.url = '기자리스트'
        }else if(data.url == '/api/adComp/list'){
          data.url = '배너 광고사 관리'
        }else if(data.url == '/api/adComp/list/selectable'){
          data.url = '배너 선택가능 광고사'
        }else if(data.url == '/api/adBanner/load'){
          data.url = '배너 리스트'
        }else if(data.url == '/api/adBanner/write'){
          data.url = '배너 생성'
        }else if(data.url == '/api/adBanner/edit'){
          data.url = '배너 수정'
        }else if(data.url == '/api/adBanner/delete'){
          data.url = '배너 삭제'
        }
      }
      this.items = fulldata;
      this.$vs.loading.close();
    }
  },
  mounted(){
    this.created();
  },
  async created(){
    // this.created();
    if(!this.$store.state.writerAll){
      try {
        let response = await this.$http.post('/api/getVarList/writer', {});
        this.writerOption = response.data;
        this.writerOption = this.writerOption.filter(e => moment(e.entry_date) > moment('2000-01-01'));
        for(let i in this.writerOption){
          const resetDate = moment(this.writerOption[i].entry_date).year(moment().format('YYYY'))
          if(moment() > resetDate){
            this.writerOption[i].resetDate = resetDate.format('YYYY-MM-DD')
          }else{
            this.writerOption[i].resetDate = resetDate.subtract(1,'year').format('YYYY-MM-DD')
          }
          this.writerOption[i].entry_date = moment(this.writerOption[i].entry_date).format('YYYY-MM-DD')
          // console.log(this.writerOption[i].entry_date)
        }
        this.$store.dispatch('SetWriterAll', {writerAll: this.writerOption});
      } catch (error) {
        this.httpErrorFunction(error);
      }
    }else{
      this.writerOption = this.$store.state.writerAll;
    }
    console.log('writer', this.writerOption);
  },
}
</script>
